import React, {useEffect, useRef, useState} from "react";
import rotateLeftIcon from '../../assets/images/r-left.svg'
import rotateRightIcon from '../../assets/images/r-right.svg'
import zoomInIcon from '../../assets/images/zoom-in.svg'
import zoomOutIcon from '../../assets/images/zoom-out.svg'
import downloadIcon from '../../assets/images/file_download_icon_green.svg'
import {replaceImageCall} from "../../api/apiCalls";

// @ts-ignore
const MediaViewer = ({ initialMedia, setRefreshRequired, objectKey, setIsLoading, showRotateIcons = true }:
                       { initialMedia: any, setRefreshRequired: any, objectKey: string, setIsLoading: any,
                       showRotateIcons: boolean}) => {
  const [media, setMedia] = useState(initialMedia);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [src, setSrc] = useState(media.url);
  const [retry, setRetry] = useState(false);

  const handleImageError = () => {
    if (!retry) {
      // Add cache-busting query parameter
      setSrc(`${media.url}?cacheBust=${new Date().getTime()}`);
      setRetry(true); // Ensure retry happens only once
    }
  };

  const updateMediaState = (updatedMedia: any) => {
    setMedia({ ...media, ...updatedMedia });
  };

  const zoomImage = (factor: number) => {
    const newZoom = (media.zoom || 1) * factor;

    // Limit zoom levels
    if (newZoom > 3) {
      updateMediaState({ zoom: 3 });
    } else if (newZoom < 0.5) {
      updateMediaState({ zoom: 0.5 });
      setOffset({ x: 0, y: 0 }); // Reset drag offset when zooming out fully
    } else {
      updateMediaState({ zoom: newZoom });
      // If zoom is less than 1, center the image
      if (newZoom <= 1) {
        setOffset({ x: 0, y: 0 });
      }
    }
  };

  const startDragging = (e: React.PointerEvent<HTMLDivElement>) => {
    if (media.zoom > 1) {
      setIsDragging(true);
      setDragStart({ x: e.clientX - offset.x, y: e.clientY - offset.y });
    }
  };

  const dragImage = (e: React.PointerEvent<HTMLDivElement>) => {
    if (isDragging) {
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;

      setOffset({ x: newX, y: newY });

      // Prevent any conflicts with other interactions
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const rotateImage = async (angle: number) => {
    if(media) {
      try {
        setIsLoading(true);
        media.rotation ||= 0
        media.rotation += angle
        media.rotation = media.rotation % 360
        media.objectKey = objectKey
        media.rotationSuffix ||= ''
        media.urlForServer = media.url.split('/images/')[0] + '/images/' + media.url.split('/images/').pop().split('.').join(media.rotationSuffix + '.')
        media.rotationSuffix += (angle === 90 ? '_l' : '_r')
        updateMediaState({ rotation: media.rotation })
        // Create a rotated image and upload it to the server
        const blobImage = await createRotatedImageBlob(media.url, media.rotation)
        const resp = await replaceImageCall(media)
        await uploadToS3WithPreSignedUrl(blobImage, resp.data.uploadURL)
        setRefreshRequired(true)
        setIsLoading(false)
      } catch (e) {
        alert(e)
        setIsLoading(false)
      }
    }
  }

  const uploadToS3WithPreSignedUrl = async (blob: Blob, uploadUrl: string): Promise<void> => {
    try {
      const response = await fetch(uploadUrl, {
          method: 'PUT', // Important: Use PUT for pre-signed URLs
          body: blob,
          headers: {
              'Content-Type': blob.type, // Important: Set Content-Type
          },
      });

      if (!response.ok) {
          const errorText = await response.text(); // Try to get error message from S3
          throw new Error(`Upload failed: ${response.status} ${response.statusText} - ${errorText}`);
      }

      console.log('Upload to S3 successful!');
    } catch (error) {
        console.error('Error uploading to S3:', error);
        throw error; // Re-throw the error to be handled by the caller
    }
  };

  // Function to create a rotated image blob using canvas
  const createRotatedImageBlob = (imageUrl: string, rotationAngle: number): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // Allow cross-origin image processing
      img.src = imageUrl;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if(!ctx) throw Error('failed to instantiate Canvas')
        // Set canvas size to fit the image
        const size = Math.max(img.width, img.height);
        canvas.width = size;
        canvas.height = size;

        // Clear canvas and rotate
        ctx.translate(size / 2, size / 2); // Move to the center
        ctx.rotate((rotationAngle * Math.PI) / 180); // Rotate the canvas
        ctx.drawImage(img, -img.width / 2, -img.height / 2); // Draw rotated image
        ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transform

        // Convert canvas to blob
        canvas.toBlob(
        (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to create image blob"));
            }
          },
          "image/png", // You might want to make this configurable
          1.0 // Quality (0.0 - 1.0)
        );
      };

      img.onerror = (err) => reject(err);
    });
  };

  const fetchImage = async (url: string): Promise<Blob> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Cache-Control': 'no-store', // Ensure no caching
      'Pragma': 'no-cache',        // Ensure no caching
    }});
  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.statusText}`);
  }
    return await response.blob();
};

  const downloadImage = async (url: string) => {
  try {
    const imageBlob = await fetchImage(url);
    if (imageBlob.size === 0) {
      throw new Error("Image blob is empty");
    }
    const imageBase64 = URL.createObjectURL(imageBlob);

    const a = document.createElement('a');
    a.style.setProperty('display', 'none');
    document.body.appendChild(a);
    a.download = url.split('/').pop() || 'download';
    a.href = imageBase64;
    a.click();
    a.remove();
    URL.revokeObjectURL(imageBase64); // Clean up the object URL after download
  } catch (error) {
    console.error("Error downloading the image:", error);
  }
};

  return (
    <div className="media-slider-outer-container">
      {/* Image */}
      <div
        className="media-slider-static-content-container-img"
        onPointerDown={startDragging}
        onPointerMove={dragImage}
        onPointerUp={stopDragging}
        onPointerLeave={stopDragging}
      >
        <img
          key={'image_'+media.id}
          className="media-slider-img"
          src={src}
          alt={media.alt || "Image"}
          style={{
            transform: `translate(${offset.x}px, ${offset.y}px) rotate(${
              media.rotation || 0
            }deg) scale(${media.zoom || 1})`,
            cursor: media.zoom > 1 ? "grab" : "default", // Show grab cursor only when zoomed in
          }}
          crossOrigin="anonymous"
          onError={handleImageError} // Handle error to retry with cache-busting
        />
      </div>

      {/* Controls */}
      <div className="media-slider-static-content-container-controls">
        {showRotateIcons && <>
          <img
            src={rotateLeftIcon}
            onClick={() => rotateImage(-90)}
            className="media-slider-rotate-left-icon"
            alt="Rotate Left"
          />
          <img
            src={rotateRightIcon}
            onClick={() => rotateImage(90)}
            className="media-slider-rotate-right-icon"
            alt="Rotate Right"
          />
        </>}
        <img
          src={downloadIcon}
          onClick={async () => await downloadImage(media.url)}
          className="cross-icon cursor_pointer media-slider-cross-icon"
          alt="Download"
        />
        <img
          src={zoomInIcon}
          color={'#149E5C'}
          onClick={() => zoomImage(1.2)}
          className="media-slider-zoom-in-icon"
          alt="Zoom In"
        />
        <img
          src={zoomOutIcon}
          color={'#149E5C'}
          onClick={() => zoomImage(0.8)}
          className="media-slider-zoom-out-icon"
          alt="Zoom Out"
        />
      </div>
    </div>
  );
};

export default MediaViewer;
