import React, {useEffect, useState} from 'react'
import closeIcon from '../../assets/images/close.png'
import downloadIcon from '../../assets/images/file_download_icon_green.svg'
import '../AssignItemsPopup/assignItemsPopup.scss'
import './mediaSlider.scss'
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react'
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Modal from 'react-modal'
import Flex from '../flex/Flex'
import i18n from "i18next";
import Loader from "../Loader/loader";
import MediaViewer from "./mediaViewer";

const customStyles = {
  content: {
    inset: '0px',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    background: 'none',
    position: 'relative',
    boxSizing: 'border-box'
  },
  overlay: {
    background: 'rgba(174,174,174,0.5)',
    backdropFilter: 'blur(15px)',
    WebkitBackdropFilter: 'blur(5px)',
  },
} as const

const MediaSlider = (props: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const [refreshRequired, setRefreshRequired] = useState<boolean>(false)
  const [isDesktop, setIsDesktop] = useState(false);
  const swiper = useSwiper();
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    const keyDownHandler = (event: any) => {
      if (event.key === 'ArrowRight') {
        event.preventDefault()
        swiper?.slideNext()
      }
      if (event.key === 'ArrowLeft') {
        event.preventDefault()
        swiper?.slidePrev()
      }
    }
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024); // Adjust breakpoint as needed
    };

    handleResize(); // Check on component mount
    window.addEventListener("resize", handleResize); // Update on window resize

    document.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const setIsLoadingFn = (value: boolean) => {
    setIsLoading(value)
  }
  const handleSlideChange = (e: any) => {
    if (props.isVideo) {
      const vidArr: any = document.getElementsByClassName('media-slider-video')
      for (const vid of vidArr) {
        vid.pause()
      }
    }
  }

  /*
  commented this, as on digital check report, this will not work; it is still required in case of
  item / container details

  const { data: { markings } = {} } = useQuery(['markingsList'], () => getMarkings(), {
    enabled: props.mediaType === 'markings',
  })
  */
  return (<>
    {isLoading && <Loader key={'isLoadingMediaSliderKey'} bgColor={'#1f1f1f4f'}/>}
    <Modal
      isOpen={true}
      onRequestClose={() => props.dismissHandler(refreshRequired)}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="media-slider-outermost-container">
        {/* Close Button */}
        <div className="media-slider-close-icon-container">
          <img
            className="cross-icon cursor_pointer media-slider-cross-icon"
            onClick={() => props.dismissHandler(refreshRequired)}
            src={closeIcon}
            alt="Close"
          />
        </div>

        {/* Image Slider */}
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          speed={0}
          modules={[Navigation, Pagination, Keyboard]}
          navigation
          allowTouchMove={!isDesktop} // Disable touch-based swipe on desktop
          pagination={{ type: "fraction" }}
          initialSlide={props.startingInd}
          onRealIndexChange={(e: any) => handleSlideChange(e)}
          keyboard={{
            enabled: true,
            onlyInViewport: true,
          }}
          style={{
            width: "100%",
            height: "calc(100vh )", // Adjust height to fit within the viewport
          }}
          lazy={true}
          preloadImages={false}
        >
          {props.mediaData &&
            props.mediaData.length > 0 &&
            props.mediaData.map((media: any) => {
              const initialMedia = {
                url: media.url,
                rotation: 0,
                zoom: 1,
                alt: 'Loading'
              };

              return (
                <SwiperSlide
                  key={media.id}
                  className="media-slider-swiper-container"
                >
                  <div className="media-slider-content-container">
                    {media.type?.toLowerCase() === "photo" ? (
                      <MediaViewer key={'mediaViewer_'+media.id}
                                   initialMedia={initialMedia}
                                   setRefreshRequired={setRefreshRequired}
                                   objectKey={props.objectKey}
                                   setIsLoading={setIsLoadingFn}
                                   showRotateIcons={props.showRotateIcons}
                      />
                      ) : media.type?.toLowerCase() === 'video' ? (
                        <div className='media-slider-video-container'>
                          <div className='media-slider-static-content-container-video'>
                            <video
                              id='media-slider-video'
                              controls
                              src={media.url}
                              className='media-slider-img media-slider-video'
                            />
                          </div>
                          <div className='media-slider-static-content-container-controls'>
                            <a href={media.url} download="video.mp4" target="_blank">
                              <img src={downloadIcon} className='cross-icon cursor_pointer media-slider-cross-icon'></img>
                            </a>
                          </div>
                        </div>
                      ) : props.mediaType === 'markings' ?
                      <div className='media-slider-static-content-container-img'>
                        <img src={media.marked_image ? media.marked_image : media.image} className='media-slider-img' />
                      </div>: null
                    }
                  </div>
                </SwiperSlide>
              )
            }
          )}
        </Swiper>
      </div>
      {props.mediaType === 'markings' && (
        <Flex justify='center' align='center' wrap='wrap' css={{ gap: 16, py: 16 }}>
          {props.mediaData2?.map((marking: any) => (
            <div key={marking.idx || marking.id} className='display_flex legend-item-container'>
              <div className='marking-legend-color-container' style={{ backgroundColor: `#${marking.color}` }}></div>
              <p className='marking-legend-text'>{JSON.parse(marking.text_i18n)[i18n.language] || marking.default_text}</p>
            </div>
          ))}
        </Flex>
      )}
    </Modal>
  </>)
}

export default MediaSlider
