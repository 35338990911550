import React from 'react'
import { css } from '@emotion/react'
import PulseLoader from 'react-spinners/PulseLoader'
import variables from '../../themes.module.scss'

const Loader = ({ bgColor = "#a5a4a44f" }: { bgColor?: string}) => {
  const override = css`
    display: block;
    margin: 0 auto;
  `

  return (
    <div
      style={{
        height: '100%',
        background: bgColor,
        // zIndex: 9999,
        zIndex: 99999999999999999999,
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)',
        position: 'fixed',
        width: '100%',
        inset: 0,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 999999999999999,
        }}
      >
        {/* <div className="loader-text">Please wait...</div> */}
        <PulseLoader color={variables.digitalMint} loading={true} css={override} size={18} margin={5} />
      </div>
    </div>
  )
}

export default Loader
