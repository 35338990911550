import React from 'react'
import { HiChevronRight } from 'react-icons/hi2'
import { Link } from 'react-router-dom'
import StatusChip from '../statusChip'
import getThumbnail from "../../utils/getThumbnail";
import {use} from "i18next";

interface ItemCardProps {
  id?: number
  title: string | null
  mark?: string | null
  externalId?: string | null
  status: string | null
  image: string | null
  url?: string
  useAnchorTag?: boolean
}

const ItemCard: React.FC<ItemCardProps> = ({ id, title, mark,
                                             externalId, status, image,
                                             url, useAnchorTag = false }) => {
  const widget = <>
    <object
      className='w-16 h-16 rounded-md'
      data={getThumbnail(image || '')}
      type="image/png"
    />
    <div className='flex flex-col items-start justify-start gap-[2px]'>
      <h3 className='my-0 text-[15px] font-semibold text-strong_graphite'>{title || '[No Title]'}</h3>
      {mark ? (<p className='my-0 text-xs leading-3 text-gray-500'>Mark: {mark || '[No Mark]'}</p>) : null}
      {externalId ? (<p className='my-0 text-xs leading-3 text-gray-500'>Id: {externalId}</p>) : null}
      <StatusChip
        status={status || '[No Status]'}
        intent={status === 'INSPECTED'
          ? 'inspected' : status === 'ASSIGNED'
            ? 'assigned' : status === 'NEW'
              ? 'new' : status === 'DRAFT'
                ? 'draft' : status === 'SUBMITTED FOR REVIEW'
                  ? 'submitted_for_review' : 'default'
        }
      />
    </div>
    <HiChevronRight className='w-6 h-6 my-auto ml-auto text-strong_graphite' />
  </>
  if(useAnchorTag) {
    return (
      <a href={url}
        className='flex items-start justify-start w-full gap-3 p-2 -ml-2 transition-colors duration-300 rounded-md hover:bg-calm_platinum/10'>
        {widget}
      </a>
    )
  } else {
    return (
      <Link to={url ?? `/dashboard/items/${id}`}
          className='flex items-start justify-start w-full gap-3 p-2 -ml-2 transition-colors duration-300 rounded-md hover:bg-calm_platinum/10'>
        {widget}
      </Link>)
  }
}

export default ItemCard
